import GenCodeCard from './GenCodeCard.vue';

import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance} from 'vue';
export default defineComponent({
    name: 'GenCodeList',
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj=reactive({
            pageListRef:null,
            pageList: {
                queryParam: {},
                modelComp:GenCodeCard,
                modelMethod: utils.Api.buildUrl("/genCode/pageData")
            }
        })
        //---------------数据表格行格式化
        const formatterBillFlag=(row:any, column:any, value:any, index:number)=>{
            if ('0' == value) {
                return "卡片";
            } else if ('1' == value) {
                return "单据";
            } else {
                return "";
            }
        }
        const filterBillFlag=(value:any, row:any)=>{
            return row.F_BILL_FLAG === value;
        }
        return{
            ...toRefs(dataObj),formatterBillFlag,filterBillFlag
        }
    },
    components: {
 
    }
});